/* src/components/Modal.css */
.modal {
    position: fixed;
    top: 0;
    right: -50%; /* Initially hidden off-screen */
    width: 50%; /* Set the modal width to cover half the screen */
    height: 100%;
    background-color: #fff;
    transition: right 0.3s ease; /* Slide-in transition */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Make the modal scrollable vertically */

  }
  
  /* Adjust styles for mobile devices (screen width <= 768px) */
  @media (max-width: 768px) {
    .modal {
        position: fixed;
        top: 0;
        right: -100%; /* Initially hidden off-screen */
        width: 100%; /* Set the modal width to cover half the screen */
        height: 100%;
        background-color: #fff;
        transition: right 0.3s ease; /* Slide-in transition */
        overflow-y: auto; /* Make the modal scrollable vertically */

    }
  }
  
  .modal.open {
    right: 0; /* Slide in from the right */
  }
  
  .modal-content {
    padding: 20px;
  }
  
  .gradient-container-success {
    background: linear-gradient(135deg, #00E92F, #2A73FF); /* Adjust the gradient colors as needed */
    padding: 20px; /* Add padding for spacing */
    border-radius: 10px; /* Optional: Add border radius for rounded corners */
    text-align: center; /* Center align the text */
  }

  .gradient-container-failure {
    background: linear-gradient(135deg, red, red); /* Adjust the gradient colors as needed */
    padding: 20px; /* Add padding for spacing */
    border-radius: 10px; /* Optional: Add border radius for rounded corners */
    text-align: center; /* Center align the text */
  }
  
  .gradient-container-success, .gradient-container-failure h2 {
    color: white; /* Set text color to white */
  }
  