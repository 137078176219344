.container {
  display: flex;
  flex-direction: row;
  /* Arrange divs horizontally */
  height: 100vh;
  /* Full height of the viewport */
}

.left,
.right {
  flex: 1;
  /* Each div takes up equal space */
}

.left {
  border-right: 1px solid #ebebeb;
  overflow: hidden;
}

.right {
  z-index: 1000000;
  overflow-y: scroll;
}


.mobanim {
  width: 100vh;
  height: 50vh;
  margin-bottom: 20vh;
}

.animbtn {
  cursor: pointer;
}


/* Media query for mobile devices */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    /* Arrange divs vertically */
  }

  .left,
  .right {
    flex: 0;
    height: 30vh;
    overflow: visible;
    /* Each div takes up equal space */
  }

  .animbtn {
    position: absolute;
    right: 0;
    /* bottom: 0; */
    top: 0;
    cursor: pointer;
  }

  .nav {
    display: none;
  }

}

.fadeInText {
  animation: fadeInAnimation 1s ease-in forwards;
  opacity: 0;
  font-size: 30px;
  font-weight: 900;
  color: gray;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}