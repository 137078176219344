.burst-animation {
  position: relative;
  width: 100px;
  height: 100px;
}

.burst-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00e92f; /* Initial color */
  animation-name: burst;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite; /* Loop the animation */
}

@keyframes burst {
  0% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(5);
      opacity: 0;
  }
  100% {
      transform: scale(10);
      opacity: 0;
  }
}
