.pulsing-circle-container {
  /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: lime; */
    /* height: 100vh; */
    /* z-index: -99; */
  }
  
  .pulsing-circle {
    position: absolute;
    top: 50%; /* Position the circle 50% from the top of its container */
    /* left: 50%;  */
    transform: translate(-50%, -50%); /* Translate the circle back by half of its own width and height */
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #2a73ff;
    animation: pulse 2s infinite alternate; /* Pulsing animation */
  }

  .pulsing-circle-small {
    position: absolute;
    top: 50%; /* Position the circle 50% from the top of its container */
    /* left: 50%;  */
    transform: translate(-50%, -50%); /* Translate the circle back by half of its own width and height */
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #00e92f;
    animation: pulse 2s infinite alternate; /* Pulsing animation */
  }
  
  
  @keyframes pulse {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(2);
    }
  }
  